<template>
  <v-container>
    <v-row justify="center" justify-sm="start">
      <v-col cols="auto">
        <h1 class="ml-sm-4 ml-md-0">
          {{ $t('Adding new deposit') }}
        </h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        v-for="depositType in depositTypes"
        :key="depositType.id"
        class="d-flex"
        cols="auto"
      >
        <v-hover v-slot:default="{hover}">
          <v-card
            :disabled="depositType.disabled"
            :elevation="hover ? 0 : 0"
            :to="{name: 'new-deposit', params: {type: depositType.id}}"
            :ripple="false"
            class="px-4 py-5 d-flex flex-column"
            max-width="345"
          >
            <v-img
              :src="depositType.image"
              class="flex-grow-0"
              height="160"
              contain
            />

            <h4 class="text-center mt-10">
              {{ depositType.title }}
            </h4>
            <v-card-text class="flex-grow-1 text-center">
              {{ depositType.text }}
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="depositType.disabled"
                color="primary"
                elevation="0"
                block
                outlined
                rounded
              >
                {{ $t('Coming soon') }}
              </v-btn>
              <v-btn
                v-else
                :to="{name: 'new-deposit', params: {type: depositType.id}}"
                color="primary"
                elevation="0"
                block
                rounded
              >
                {{ $t('Create') }}
                {{ depositType.title }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'DepositNewSelectType',
  computed: {
    ...mapState({
      types: state => state.config.deposit?.types || {}
    }),
    depositTypes () {
      return Object.keys(this.types)
        .map(type => ({id: type, ...this.types[type]}))
        .filter(type => type.createInAccount)
        .sort((dt1, dt2) => dt1.order - dt2.order)
    }
  }
}
</script>

<style lang="scss" scoped>
  a.v-card.v-card--link:hover {
    text-decoration: none;
  }
</style>
