<template>
  <div>
    <v-container>
      <app-link-back-to-deposits
        :text="$t('Back to deposit type selection')"
        :to="{name: 'new-deposit-select-type'}"
      />
      <h1>
        {{ $t('New deposit') }}
        {{ depositType ? depositType.title : '' }}
      </h1>
    </v-container>

    <v-form v-if="depositType" ref="form" v-model="valid">
      <v-container class="deposit-container pb-0 pb-sm-3">
        <v-row class="mx-n3 mx-sm-0" no-gutters>
          <v-col>
            <v-card
              :ripple="false"
              class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
              flat
            >
              <form-deposit
                :deposit-type="depositType"
                :disabled="saving"
                :server-feedback="serverFeedback"
                :value="deposit"
                @input="input"
              />

              <v-row>
                <v-col class="d-flex align-start justify-end flex-wrap">
                  <v-btn
                    :ripple="false"
                    :to="backRoute"
                    class="mb-3"
                    outlined
                    rounded
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                  <v-btn
                    :loading="saving"
                    :ripple="false"
                    class="ml-5"
                    color="primary"
                    elevation="0"
                    rounded
                    @click="submit"
                  >
                    {{ $t('Create') }}
                    {{ depositType.title }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import FormDeposit from '@/components/FormDeposit'
import error401handler from '@/mixins/error-401-handler'
import {sections} from '@/store/const-deposit-statuses'

export default {
  name: 'DepositNew',
  components: {AppLinkBackToDeposits, FormDeposit},
  mixins: [error401handler],
  data () {
    return {
      saving: false,
      keepUnsavedLabel: '',
      deposit: {
        project: null,
        name: null,
        description: null,
        type: null,
        content: null,
        language: null,
        actors: null
      },
      valid: true,
      serverFeedback: {
        name: null,
        description: null,
        type: null,
        content: null,
        actors: null
      }
    }
  },
  computed: {
    ...mapState({
      isBusinessAccount: state => state.profile.accountType === 'business',
      depositTypes: state => state.config.deposit.types,

      currentPagePrv: state => state.deposits.pagination.currentPage,
      currentPageBiz: state => state.depositsBusiness.pagination.currentPage,
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    currentPage () {
      return this.isBusinessAccount ? this.currentPageBiz : this.currentPagePrv
    },

    depositType () {
      return this.depositTypes[this.$route.params.type]
    },
    contentType () {
      return this.depositType.content_type
    },
    backRoute () {
      let section = sections.find(s => {
        return s.backFromRoutes.includes(this.$route.name)
      })

      let route = {
        name: 'deposits',
        params: {
          projectId: 'all', // this.deposit?.project?.id || 'all',
          sectionId: section.id
        }
      }
      if (this.currentPage > 1) {
        route.query = {page: this.currentPage}
      }
      return route
    }
  },
  async created () {
    if (!this.depositTypes[this.$route.params.type]) {
      return this.$router.replace({name: 'new-deposit-select-type'})
    }
    this.deposit.type = this.$route.params.type

    // Restore unsaved form state, if present
    this.keepUnsavedLabel = `new-deposit-${this.deposit.type}`
    let unsaved = localStorage.getItem(this.keepUnsavedLabel)
    if (unsaved) {
      unsaved = JSON.parse(unsaved)
      this.deposit = unsaved.deposit
    }

    // Files cannot be saved to localStorage anyway
    if (this.contentType === 'file') {
      this.deposit.content = []
    }
  },
  beforeDestroy () {
    localStorage.removeItem(this.keepUnsavedLabel)
  },
  methods: {
    ...mapMutations({
      showNotify: 'notify/show',
    }),
    ...mapActions({
      addDepositPrv: 'deposits/dpAdd',
      addDepositBiz: 'depositsBusiness/dpAdd',
      addDepositWithFilesPrv: 'deposits/dpAddWithFiles',
      addDepositWithFilesBiz: 'depositsBusiness/dpAddWithFiles',
      clearDepositsPrv: 'deposits/dpClear',
      clearDepositsBiz: 'depositsBusiness/dpClear',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    addDeposit () {
      return (this.isBusinessAccount ? this.addDepositBiz : this.addDepositPrv)(...arguments)
    },
    addDepositWithFiles () {
      return (this.isBusinessAccount ? this.addDepositWithFilesBiz : this.addDepositWithFilesPrv)(...arguments)
    },
    clearDeposits () {
      return (this.isBusinessAccount ? this.clearDepositsBiz : this.clearDepositsPrv)(...arguments)
    },

    input (value) {
      this.deposit = value
      localStorage.setItem(this.keepUnsavedLabel, JSON.stringify({
        deposit: this.deposit
      }))
    },
    clearErrors () {
      for (let key of Object.keys(this.serverFeedback)) {
        this.serverFeedback[key] = null
      }
    },
    async submit () {
      if (!this.$refs.form.validate()) return

      this.saving = true
      this.$refs.form.resetValidation()
      this.clearErrors()

      try {
        if (this.contentType === 'file') {
          await this.addDepositWithFiles(this.deposit)
        } else {
          await this.addDeposit(this.deposit)
        }
        await this.clearDeposits()
        this.showNotify({
          text: this.$t('Deposit draft successfully created'),
          color: 'success'
        })
        await this.$router.push(this.backRoute)
      } catch (e) {
        this.saving = false
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = {...e.response.data.message}
        }
      }
    }
  }
}
</script>
